import { datadogRum } from '@datadog/browser-rum';
import { Transition } from '@headlessui/react';
import {
  ArrowDownRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { produce, setAutoFreeze } from 'immer';
import _, { first, isNil, last, round, sum } from 'lodash';
import {
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import Badge, { BadgeColor } from 'src/components/Badge';
import {
  ClickAndDragProvider,
  useClickAndDrag,
} from 'src/components/ClickAndDrag';
import { FormattedNumberField } from 'src/components/Fields';
import { useTooltip } from 'src/components/MouseTooltip';
import { useToast } from 'src/components/Toast';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { unreachable } from 'src/typeUtils';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { formatCurrency } from 'src/utils/formatters';
import { formatCurrencyValue } from '../../Alpaca/alpaca_utils';
import { QuotePriceEditable } from '../../Alpaca/Components/AlpacaQuotePriceEditable';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowType } from '../../types';
import {
  CurrencyValueFlat,
  CurrencyValueType,
  ZERO_FLAT,
} from '../../types_common/price';
import {
  MonthlyMinProductCode,
  PenguinAdditionalData,
  PenguinApprovalLevels,
  PenguinCV,
  PenguinEffectiveContractualMin,
  PenguinManualQuote,
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinPricingInformation,
  PenguinProduct,
  PenguinProductPrice,
  PenguinProductPrices,
  PenguinProductWithVolume,
  PenguinQuotePrice,
  PenguinRecommendedQuote,
  PenguinSupportedCurrency,
  PenguinTier,
  PriceType,
} from '../penguin_types';
import {
  computeEffectiveMinimumContractualSpend,
  getMonthlyMinForTargetMonthlyContractualSpend,
  TARGET_MINIMUM_SPEND_PERCENTAGE,
} from '../penguin_utils';
import EditableIndicator from './EditableIndicator';
import { FirstYearSpendChart } from './FirstYearSpendChart';
import { MonthlySpendChart } from './MonthlySpendChart';
import AnnualCosts from './PenguinQuoteTable__AnnualCosts';
import RampedDetails from './RampedDetails';
import TieredDetails from './TieredDetails';

// For transfer products with 0 rack rate, we have custom overrides for sticker
// price that are meant to be used only by the monthly and yearly spend chart. Otherwise,
// the chart looks like the customer is getting a bad deal, because going with
// the "sticker price" of 0 is cheaper than the quoted price. However, in
// reality, customers can't self-serve transfer products.
// See this otter transcript for more context:
// https://otter.ai/s/WMRf1LxARta0sBrxlMHZnA?snpt=true
export function getStickerPriceForPenguinProductWithMaybeFalseTransferOverrides(
  product: PenguinProduct,
  pricingInfo: PenguinPricingInformation | undefined,
  productPrice: PenguinProductPrice | undefined,
  quoteCurrency: PenguinSupportedCurrency,
): PenguinCV {
  const skuGroup = productPrice?.skuGroup;
  if (
    skuGroup === 'Transfers' &&
    (isNil(pricingInfo?.selfServe) || pricingInfo.selfServe.value === 0)
  ) {
    const STANDARD_BPS = 0.5;
    const SAME_DAY_BPS = 0.9;
    const INSTANT_BPS = 1.5;
    switch (productPrice?.ProductCode) {
      case 'TRFSVO - UF': {
        // Transfer (Standard ACH) - Vanilla Originators - Fixed
        return {
          type: CurrencyValueType.FLAT,
          value: Math.min(
            productPrice.priceCap?.value ?? Infinity,
            Math.max(
              productPrice.priceFloor?.value ?? 0,
              STANDARD_BPS * (product.transactionSize?.value ?? 0) * 0.01,
            ),
          ),
          currency: quoteCurrency,
        };
      }
      case 'TRFSVO - BPS':
        // Transfer (Standard ACH) - Vanilla Originators - bps
        return { type: CurrencyValueType.PERCENT, value: STANDARD_BPS };
      case 'TRFSDVO - UF':
        // Transfer (Same-day ACH) - Vanilla Originators - Fixed
        return {
          type: CurrencyValueType.FLAT,
          value: Math.min(
            productPrice.priceCap?.value ?? Infinity,
            Math.max(
              productPrice.priceFloor?.value ?? 0,
              SAME_DAY_BPS * (product.transactionSize?.value ?? 0) * 0.01,
            ),
          ),
          currency: quoteCurrency,
        };
      case 'TRFSDVO - BPS':
        // Transfer (Same-day ACH) - Vanilla Originators - bps
        return { type: CurrencyValueType.PERCENT, value: SAME_DAY_BPS };
      case 'TRFIP - UF':
        // Transfer (Instant Payouts) - Fixed
        return {
          type: CurrencyValueType.FLAT,
          value: Math.min(
            productPrice.priceCap?.value ?? Infinity,
            Math.max(
              productPrice.priceFloor?.value ?? 0,
              INSTANT_BPS * (product.transactionSize?.value ?? 0) * 0.01,
            ),
          ),
          currency: quoteCurrency,
        };
      case 'TRFIP - BPS':
        // Transfer (Instant Payouts) - bps
        return { type: CurrencyValueType.PERCENT, value: INSTANT_BPS };
    }
  }
  return (
    pricingInfo?.selfServe ?? {
      currency: quoteCurrency,
      type: CurrencyValueType.FLAT,
      value: 0,
    }
  );
}

// For a ramp of arbitrary CurrencyValueFlat, smooths it out by clamping each
// number to the largest number that follows it. This ensures that the output
// ramp is monotonically increasing
function makeRampIncreasing(ramp: CurrencyValueFlat[]) {
  setAutoFreeze(false);
  return produce(ramp, (draftRamp) => {
    for (let i = draftRamp.length - 2; i >= 0; i--) {
      draftRamp[i].value = Math.min(draftRamp[i].value, draftRamp[i + 1].value);
    }
  });
}

export function calculateLinearRampUp({
  steps,
  volume,
  start,
}: {
  steps: number;
  volume: number;
  start: number;
}): number[] {
  const step = (volume - start) / steps;
  return Array.from({ length: steps }, (_, i) => {
    return Math.round(Math.min(start + step * (i + 1), volume));
  });
}

function getVolumeRampUpRows(product: PenguinProductWithVolume) {
  if (product.rampType === 'custom' && product.customRampUp) {
    return product.customRampUp;
  } else if (product.rampType === 'linear' && product.linearRampUpConfig) {
    const { start, months } = product.linearRampUpConfig;
    return calculateLinearRampUp({
      steps: months,
      volume: product.volume.value,
      start: start,
    });
  } else if (
    product.rampType === 'linear_quarterly' &&
    product.linearRampUpConfig
  ) {
    const { start, months } = product.linearRampUpConfig;
    const numQuarters = Math.ceil(months / 3);
    const rampUpByQuarter = calculateLinearRampUp({
      steps: numQuarters,
      volume: product.volume.value,
      start: start,
    });
    const rampUpByMonths = _.flatten(
      rampUpByQuarter.map((valueForQuarter) => [
        valueForQuarter,
        valueForQuarter,
        valueForQuarter,
      ]),
    );
    return rampUpByMonths.slice(0, months);
  }
  return [];
}

export function calculateCostForEachMonth(params: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  useStickerPriceAsQuotePrice?: boolean;
}): CurrencyValueFlat[] {
  const { pricingFlow, useStickerPriceAsQuotePrice = false } = params;
  const { products, recommendedQuote, manualQuote } = pricingFlow;
  const productPrices = pricingFlow.pricingSheetData.productInfo;
  const subscriptionTerms = pricingFlow.additionalData.subscriptionTerms;

  let monthlyCosts: number[] = Array(subscriptionTerms).fill(0);

  products.forEach((product) => {
    const manualPrice = manualQuote?.products?.[product.id];
    const productPrice = productPrices[product.id];
    if (isNil(productPrice)) {
      return;
    }

    const pricingInfo =
      productPrices[product.id]?.currentPricingCurve.pricingInformation;

    const quotePrice = useStickerPriceAsQuotePrice
      ? (getStickerPriceForPenguinProductWithMaybeFalseTransferOverrides(
          product,
          pricingInfo,
          productPrice,
          (params.pricingFlow.additionalData as PenguinAdditionalData)
            .quoteCurrency,
        ) ?? ZERO_FLAT(pricingFlow.additionalData.quoteCurrency))
      : (manualPrice ??
        recommendedQuote.products?.[product.id] ??
        ZERO_FLAT(pricingFlow.additionalData.quoteCurrency));
    const skuGroup = productPrice.skuGroup;

    if (
      skuGroup === 'Support' ||
      skuGroup === 'Implementation Services' ||
      skuGroup === 'Partnerships'
    ) {
      return;
    }
    const volumePerMonth = getVolumeRampUpRows(product);
    for (let i = 0; i < monthlyCosts.length; i++) {
      // The volume ramp up could be shorter than the subscription term,
      // so for the remaining months we would just use the volume at scale
      const volumeAtThisMonth = volumePerMonth[i] ?? product.volume.value;

      monthlyCosts[i] +=
        monthlyRevenueForQuotePrice(
          quotePrice,
          volumeAtThisMonth,
          product.transactionSize?.value,
          productPrice,
          i,
        ) ?? 0;
    }
  });
  return monthlyCosts.map((rawCostValue) => {
    return {
      type: CurrencyValueType.FLAT,
      value: rawCostValue,
      currency: pricingFlow.additionalData.quoteCurrency,
    };
  });
}

// Synced with server/src/calculators/penguin_calculator.ts
export function calculateFinalMonthlyCost(params: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  ignoreProfessionalServices?: boolean;
  useSelfServeInsteadOfManualPrice?: boolean;
}) {
  const { products, recommendedQuote, manualQuote } = params.pricingFlow;
  const productPrices = params.pricingFlow.pricingSheetData.productInfo;
  const ignoreProfessionalServices = params.ignoreProfessionalServices ?? false;

  let monthlyCost = 0;
  products.forEach((product) => {
    const productPrice = productPrices[product.id];
    const pricingInfo =
      productPrices[product.id]?.currentPricingCurve.pricingInformation;
    if (isNil(productPrice)) {
      return;
    }
    const skuGroup = productPrice.skuGroup;
    const manualPrice = manualQuote?.products?.[product.id];
    const quotePrice = ((): PenguinQuotePrice => {
      if (!params.useSelfServeInsteadOfManualPrice) {
        return (
          manualPrice ??
          recommendedQuote.products?.[product.id] ?? {
            currency: params.pricingFlow.additionalData.quoteCurrency,
            type: CurrencyValueType.FLAT,
            value: 0,
          }
        );
      } else {
        return getStickerPriceForPenguinProductWithMaybeFalseTransferOverrides(
          product,
          pricingInfo,
          productPrice,
          (params.pricingFlow.additionalData as PenguinAdditionalData)
            .quoteCurrency,
        );
      }
    })();

    if (ignoreProfessionalServices) {
      if (
        skuGroup === 'Support' ||
        skuGroup === 'Implementation Services' ||
        skuGroup === 'Partnerships'
      ) {
        return;
      }
    }

    monthlyCost +=
      monthlyRevenueForQuotePrice(
        quotePrice,
        product.volume.value,
        product.transactionSize?.value,
        productPrice,
        null,
      ) ?? 0;
  });
  return monthlyCost;
}

export function getVolumeForEachTier({
  tiers,
  volume,
}: {
  tiers: PenguinTier[];
  volume: number;
}): number[] {
  let remainingVolume = volume;
  const volumeForEachTier = new Array(tiers.length).fill(0);

  for (let index = 0; index < tiers.length; index++) {
    const currentTier: PenguinTier = tiers[index];
    const nextTier: PenguinTier | undefined = tiers[index + 1];

    const volumeAvailableForCurrentTier = nextTier
      ? nextTier.minimum.value - currentTier.minimum.value
      : Infinity;

    const volumeThatCountsTowardCurrentTier = Math.min(
      remainingVolume,
      volumeAvailableForCurrentTier,
    );
    volumeForEachTier[index] = volumeThatCountsTowardCurrentTier;

    remainingVolume -= volumeThatCountsTowardCurrentTier;
    if (remainingVolume <= 0) {
      break;
    }
  }
  return volumeForEachTier;
}

export function monthlyRevenueForQuotePrice(
  productQuotePrice: PenguinQuotePrice,
  expectedVolume: number,
  transactionSize: number | undefined,
  productData: PenguinProductPrice | undefined,
  monthIdx: number | null,
): number | null {
  const pricingInfo = productData?.currentPricingCurve.pricingInformation;
  if (isNil(pricingInfo) || isNil(productData) || pricingInfo.unpriceable) {
    return null;
  }
  const priceBilledForNumberOfMonths =
    productData.priceBilledForNumberOfMonths ?? 1;

  if (priceBilledForNumberOfMonths == null) {
    datadogRum.addError(new Error(`priceBilledForNumberOfMonths is null`), {
      message: `priceBilledForNumberOfMonths is null for ${productData.name}, falling back to 1`,
    });
  }

  switch (productQuotePrice.type) {
    case CurrencyValueType.FLAT:
      // TODO(george) implement complex transfer pricing for fixed products
      return (
        (expectedVolume * productQuotePrice.value) /
        priceBilledForNumberOfMonths
      );
    case CurrencyValueType.PERCENT:
      if (transactionSize == null) {
        return (
          (expectedVolume * productQuotePrice.value) /
          priceBilledForNumberOfMonths
        );
      } else {
        const priceCap = productData.priceCap ?? productQuotePrice;
        const priceFloor = productData.priceFloor ?? productQuotePrice;
        return (
          expectedVolume *
          Math.max(
            priceFloor.value,
            Math.min(
              priceCap.value,
              (productQuotePrice.value * transactionSize * 0.01) /
                priceBilledForNumberOfMonths,
            ),
          )
        );
      }
    case 'tiered':
      const { tiers } = productQuotePrice;
      if (tiers.length < 2) {
        throw new Error(
          `Tiered quote must have at least two tiers, got ${tiers.length}`,
        );
      }

      const volumeForTiers = getVolumeForEachTier({
        tiers: tiers,
        volume: expectedVolume,
      });

      let revenue = 0;
      for (let [tier, volume] of _.zip<PenguinTier, number>(
        tiers,
        volumeForTiers,
      ) as [PenguinTier, Number][]) {
        revenue += (volume as number) * tier.currencyValue.value;
      }

      return revenue / priceBilledForNumberOfMonths;
    case 'ramped':
      const { rampValues } = productQuotePrice;
      if (!isNil(monthIdx)) {
        return rampValues[monthIdx]?.value ?? 0;
      } else {
        return last(rampValues)?.value ?? 0;
      }
    default:
      unreachable(productQuotePrice);
  }
}

export default function PenguinQuoteTable() {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();
  const { recommendedQuote, manualQuote, products, approvalLevels } =
    pricingFlow;

  const productPrices = pricingFlow.pricingSheetData.productInfo;

  const [productIdToEditTiers, setProductIdToEditTiers] = useState<
    string | undefined
  >(undefined);
  const [productIdToEditRamps, setProductIdToEditRamps] = useState<
    string | undefined
  >(undefined);

  const [isTierEditorOpen, setIsTierEditorOpen] = useState(false);
  const [isRampEditorOpen, setIsRampEditorOpen] = useState(false);

  const showTierEditorForProduct = useCallback(
    (productId: string) => {
      setProductIdToEditTiers(productId);
      setIsTierEditorOpen(true);
    },
    [setProductIdToEditTiers, setIsTierEditorOpen],
  );
  const showRampEditorForProduct = useCallback(
    (productId: string) => {
      setProductIdToEditRamps(productId);
      setIsRampEditorOpen(true);
    },
    [setProductIdToEditRamps, setIsRampEditorOpen],
  );

  const closeTierEditor = useCallback(
    () => setIsTierEditorOpen(false),
    [setIsTierEditorOpen],
  );
  const closeRampEditor = useCallback(
    () => setIsRampEditorOpen(false),
    [setIsRampEditorOpen],
  );

  if (!recommendedQuote || !productPrices) {
    console.log('ERROR: no recommended quote');
    // @TODO(fay) better error message about how we couldn't generate a recommendation and log the error
    return <>Couldn't generate recommended pricing</>;
  } else {
    // loaded
    console.log('Loaded recommendedQuote and productPrices');
  }

  const monthlyCost = calculateFinalMonthlyCost({
    pricingFlow,
    ignoreProfessionalServices: true,
  });

  const { professionalServicesProducts, mainProducts } = products.reduce(
    (acc, product: PenguinProductWithVolume) => {
      const group = [
        'Support',
        'Implementation Services',
        'Partnerships',
      ].includes(productPrices[product.id]?.skuGroup ?? 'NO')
        ? 'professionalServicesProducts'
        : 'mainProducts';
      acc[group].push(product);
      return acc;
    },
    {
      professionalServicesProducts: [] as PenguinProductWithVolume[],
      mainProducts: [] as PenguinProductWithVolume[],
    },
  );

  return (
    <div className="flex flex-col">
      <TieredDetails
        isOpen={isTierEditorOpen}
        productPrices={productPrices}
        pricingFlow={pricingFlow}
        updateFlow={updateFlow}
        productIdToEdit={productIdToEditTiers}
        onCancel={closeTierEditor}
        onSave={closeTierEditor}
      />
      <RampedDetails
        isOpen={isRampEditorOpen}
        productPrices={productPrices}
        productIdToEdit={productIdToEditRamps}
        onCancel={closeRampEditor}
        onSave={closeRampEditor}
      />

      {mainProducts.length > 0 && (
        <ProductTable
          sectionName="Products"
          products={mainProducts}
          productPrices={productPrices}
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          approvalLevels={approvalLevels}
          updateFlow={updateFlow}
          showTierEditorForProduct={showTierEditorForProduct}
          showRampEditorForProduct={showRampEditorForProduct}
          showVolumeColumn={true}
          subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
        />
      )}

      {professionalServicesProducts.length > 0 && (
        <ProductTable
          // @ts-ignore
          sectionName={
            // @ts-ignore
            pricingFlow.type === PricingFlowType.COMPLEX_DEMO
              ? 'Support'
              : 'Platform and Professional Services'
          }
          products={professionalServicesProducts}
          productPrices={productPrices}
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          approvalLevels={approvalLevels}
          updateFlow={updateFlow}
          showTierEditorForProduct={showTierEditorForProduct}
          showRampEditorForProduct={showRampEditorForProduct}
          showVolumeColumn={false}
          subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
          // @ts-ignore
          isComplexDemo={pricingFlow.type === PricingFlowType.COMPLEX_DEMO}
        />
      )}

      <div className="border-dash mb-4 mt-2 h-0 self-stretch border-b-2 border-dashed border-gray-200"></div>

      <div className="self-end">
        <MonthlyMinimum
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          updateFlow={updateFlow}
          pricingFlow={pricingFlow}
          monthlyCost={monthlyCost}
        />

        <AnnualCosts pricingFlow={pricingFlow} productPrices={productPrices} />
      </div>
      <MonthlySpendChart />
      <FirstYearSpendChart
        pricingFlow={pricingFlow}
        productPrices={productPrices}
      />
    </div>
  );
}

function ProductTable(props: {
  sectionName: string;
  products: PenguinProductWithVolume[];
  productPrices: PenguinProductPrices;
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  showTierEditorForProduct: (product: string) => void;
  showRampEditorForProduct: (product: string) => void;
  showVolumeColumn: boolean;
  subscriptionTerms: number;
  isComplexDemo?: boolean;
}) {
  const { setTooltipText } = useTooltip();
  const {
    sectionName,
    products,
    productPrices,
    recommendedQuote,
    manualQuote,
    updateFlow,
    approvalLevels,
    showTierEditorForProduct,
    showRampEditorForProduct,
    showVolumeColumn,
    subscriptionTerms,
  } = props;

  const { pricingFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  // You only want to use the products that were passed into this particular ProductsTable
  const monthlyCost = calculateFinalMonthlyCost({
    pricingFlow: { ...pricingFlow, products },
  });

  const [selectedQuotePrices, setSelectedQuotePrices] = useState<boolean[]>(
    new Array(products.length).fill(false),
  );
  useEffect(() => {
    // keep the tooltipText up to date with the selectedQuotePrices
    if (_.some(selectedQuotePrices)) {
      const selectQuotes = products
        .filter((p, i) => selectedQuotePrices[i])
        .map((product) => {
          const recommendedPrice = recommendedQuote.products[product.id];
          const manualPrice = manualQuote?.products?.[product.id];
          const quotePrice = manualPrice ??
            recommendedPrice ?? {
              currency: pricingFlow.additionalData.quoteCurrency,
              type: CurrencyValueType.FLAT,
              value: 0,
            };
          return fixedPrice(quotePrice);
        });
      const addLines =
        '  ' + selectQuotes.map((p) => `$${p.toFixed(3)}`).join('\n+ ');
      const sumOfSelectedQuotes = _.sum(selectQuotes);
      setTooltipText(`${addLines}\n= $${sumOfSelectedQuotes.toFixed(3)}`);
    }
    return () => {
      setTooltipText(null);
    };
  }, [selectedQuotePrices]);

  useEffect(() => {
    // clear selectedQuotePrices on any other click event
    function clearSelectedQuotePrices() {
      setSelectedQuotePrices(new Array(products.length).fill(false));
    }
    document.addEventListener('click', clearSelectedQuotePrices);
    return () => {
      document.removeEventListener('click', clearSelectedQuotePrices);
    };
  }, []);

  return (
    <div className="my-2 w-full self-stretch">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full min-w-full border-separate border-spacing-0">
          <QuoteTableHeader
            sectionName={sectionName}
            showVolumeColumn={showVolumeColumn}
          />
          <tbody>
            {products.length > 0
              ? products.map((product: PenguinProductWithVolume, idx) => {
                  const hasTopBorder =
                    idx === 0 || !selectedQuotePrices[idx - 1];

                  const hasBottomBorder =
                    idx === selectedQuotePrices.length - 1 ||
                    !selectedQuotePrices[idx + 1];
                  return (
                    <CurrentQuoteTableRow
                      isComplexDemo={props.isComplexDemo}
                      key={product.id}
                      product={product}
                      productPrices={productPrices}
                      recommendedQuote={recommendedQuote}
                      manualQuote={manualQuote}
                      approvalLevels={approvalLevels}
                      updateFlow={updateFlow}
                      showTierEditorForProduct={showTierEditorForProduct}
                      showRampEditorForProduct={showRampEditorForProduct}
                      showVolumeColumn={showVolumeColumn}
                      subscriptionTerms={subscriptionTerms}
                      onQuotePriceCtrlClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        const focusedElement: HTMLElement | null =
                          document.activeElement as HTMLElement;
                        if (
                          focusedElement &&
                          typeof focusedElement.blur === 'function'
                        ) {
                          focusedElement.blur();
                        }

                        const copy = [...selectedQuotePrices];
                        copy[idx] = !copy[idx];
                        setSelectedQuotePrices(copy);
                      }}
                      quotePriceClassName={
                        selectedQuotePrices[idx]
                          ? classNames(
                              'border-dashed border-x border-x-violet-600',
                              hasTopBorder
                                ? 'border-t border-t-violet-600'
                                : '',
                              hasBottomBorder
                                ? 'border-b border-b-violet-600'
                                : '',
                            )
                          : 'border-x border-x-transparent'
                      }
                    />
                  );
                })
              : null}
          </tbody>
          <QuoteTableFooter
            monthlyCost={monthlyCost}
            totalColumns={showVolumeColumn ? 8 : 7}
            isComplexDemo={props.isComplexDemo}
          />
        </table>
      </div>
    </div>
  );
}

function QuoteTableFooter(props: {
  isComplexDemo?: boolean;
  monthlyCost: number;
  totalColumns: number;
}) {
  const { monthlyCost, totalColumns } = props;
  const { pricingFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();
  return (
    <tfoot>
      <tr>
        <td
          colSpan={totalColumns - 3}
          className="whitespace-nowrap rounded-bl-xl bg-slate-50 px-6 py-4"
        ></td>
        <th
          scope="col"
          colSpan={2}
          className="bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {props.isComplexDemo ? '' : 'Total est. monthly revenue'}
        </th>
        <th
          scope="col"
          className="rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {props.isComplexDemo
            ? ''
            : formatCurrencyValue(
                {
                  type: CurrencyValueType.FLAT,
                  value: monthlyCost,
                  currency: pricingFlow.additionalData.quoteCurrency,
                },
                2,
              )}
        </th>
      </tr>
    </tfoot>
  );
}

function QuoteTableHeader(props: {
  sectionName: string;
  showVolumeColumn: boolean;
}) {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          {props.sectionName}
        </th>
        {props.showVolumeColumn && (
          <th
            scope="col"
            className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
          >
            Volume
          </th>
        )}
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Rack rate
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          L1 price
        </th>
        <th
          scope="col"
          className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Suggested price
          <span className="tooltip z-20 -ml-60 -mt-20 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            We take your products and estimated monthly volumes and we come up{' '}
            <br />
            with an overall monthly minimum and price per product that minimizes{' '}
            <br />
            the approval level needed per product.
          </span>
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Quote price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Approval level
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Est. monthly revenue
        </th>
      </tr>
    </thead>
  );
}

const MINIMUMS_DECIMAL_PLACES = 0;
function MinimumRampUpRow(props: {
  isLastRow: boolean;
  quoteValue: CurrencyValueFlat;
  percentageOfMonthlyRevenue: number;
  costForThisMonth: CurrencyValueFlat;
  suggestedMinimumValue: CurrencyValueFlat;
  updateValue: (value: CurrencyValueFlat) => void;
  month: number;
}) {
  const {
    isLastRow,
    quoteValue,
    percentageOfMonthlyRevenue,
    costForThisMonth,
    suggestedMinimumValue,
    updateValue,
    month,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const { pricingFlow, editMode } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  return (
    <>
      <td className="h-full flex flex-row items-center gap-2 bg-gray-100 px-6 py-4 pr-24 text-sm text-gray-500">
        <ArrowDownRightIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
        Month {month}
      </td>
      <td className="bg-gray-100 px-6 text-sm text-gray-500">
        {!isLastRow && (
          <button
            onClick={() => {
              updateValue({
                value: suggestedMinimumValue.value,
                type: CurrencyValueType.FLAT,
                currency: pricingFlow.additionalData.quoteCurrency,
              });
            }}
            disabled={!editMode}
          >
            <Badge color="green">
              {formatCurrencyValue(
                {
                  type: CurrencyValueType.FLAT,
                  value: suggestedMinimumValue.value,
                  currency: pricingFlow.additionalData.quoteCurrency,
                },
                MINIMUMS_DECIMAL_PLACES,
              )}
            </Badge>
          </button>
        )}
      </td>
      <td
        className="h-full bg-gray-100 text-sm text-gray-500"
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {isLastRow ? (
          <div className="px-4 font-semibold">
            {formatCurrencyValue(quoteValue, MINIMUMS_DECIMAL_PLACES)}
          </div>
        ) : (
          // minimum ramp ups
          <QuotePriceEditable
            quotePrice={quoteValue}
            updateQuotePrice={(quotePrice) => {
              if (quotePrice && quotePrice.type === CurrencyValueType.FLAT) {
                updateValue(quotePrice);
              } else {
                datadogRum.addError(`Invalid quote price type: ${quotePrice}`);
              }
            }}
            quoteCurrency={pricingFlow.additionalData.quoteCurrency}
            showChangePriceTypeMenu={false}
            numDecimals={MINIMUMS_DECIMAL_PLACES}
            // Everything below this is not actually used
            validPriceTypes={[CurrencyValueType.FLAT]}
            stickerPrice={quoteValue} // dummy value
            cost={quoteValue} // dummy value
            productName={''} // dummy value
            validTierMinimumTypes={[]} // dummy value
            tierable={false}
          />
        )}
      </td>
      <td className="whitespace-nowrap bg-gray-100 align-middle">
        <span className="px-6 text-sm text-gray-500">
          {formatCurrencyValue(costForThisMonth, MINIMUMS_DECIMAL_PLACES)}
        </span>
      </td>
      <td className="bg-gray-100">
        <span className="px-6 text-sm text-gray-500">
          {_.isFinite(percentageOfMonthlyRevenue)
            ? `${percentageOfMonthlyRevenue}%`
            : 'N/A'}
        </span>
      </td>
    </>
  );
}

export function getMonthlyMinimumRampValues({
  pricingFlow,
}: {
  pricingFlow: PenguinPricingFlow;
}): CurrencyValueFlat[] | null {
  // If there is no minimumRampUp return null
  // If there is a ramp returns the quote values for each month with default value set
  const minimumRampUp = pricingFlow.additionalData.minimumRampUp;
  const hasMonthlyMinimumRamp = Array.isArray(minimumRampUp);
  if (hasMonthlyMinimumRamp) {
    const { recommendedQuote, manualQuote } = pricingFlow;
    const monthlyMinimum =
      manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum;
    return minimumRampUp.map((value) => value ?? monthlyMinimum);
  } else {
    return null;
  }
}

function MonthlyMinimum(props: {
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  monthlyCost: number;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}) {
  const {
    recommendedQuote,
    manualQuote,
    updateFlow,
    pricingFlow,
    monthlyCost,
  } = props;
  const { editMode } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const idFixedRadio = useId();
  const idRampedRadio = useId();

  const monthlyMinProductCodeOptions = ((): MonthlyMinProductCode[] => {
    // #PenguinDefaultMonthlyMinProductCode
    // The first element of this array MUST match the default monthly minimum type!
    switch (pricingFlow.additionalData.pricebook) {
      case 'CPQ':
        switch (pricingFlow.additionalData.quoteCurrency) {
          case 'USD':
            return ['MIN - FLAT', 'MIN - CRA', 'MIN - SHA'];
          case 'EUR':
            return ['MIN - FLAT'];
          case 'GBP':
            return ['MIN - FLAT'];
          case 'CAD':
            return ['MIN - FLAT'];
          default:
            const typecheck: never = pricingFlow.additionalData.quoteCurrency;
            datadogRum.addError(
              `got unexpected quote currency ${pricingFlow.additionalData.quoteCurrency}`,
            );
            return typecheck;
        }
      case 'Partnership':
        switch (pricingFlow.additionalData.quoteCurrency) {
          case 'USD':
            return ['MIN - FLAT', 'MIN - CRA', 'MIN - SHA', 'MIN - FLATEC'];
          case 'EUR':
            return ['MIN - FLATEC'];
          case 'GBP':
            return ['MIN - FLATEC'];
          case 'CAD':
            return ['MIN - FLATEC'];
          default:
            const typecheck: never = pricingFlow.additionalData.quoteCurrency;
            datadogRum.addError(
              `got unexpected quote currency ${pricingFlow.additionalData.quoteCurrency}`,
            );
            return typecheck;
        }
      default:
        const typecheck: never = pricingFlow.additionalData.pricebook;
        datadogRum.addError(
          `got unexpected quote currency ${pricingFlow.additionalData.pricebook}`,
        );
        return typecheck;
    }
  })();
  const monthlyMinProductCodeToName: Record<
    MonthlyMinProductCode,
    { name: string; id: string }
  > = {
    'MIN - CRA': { name: 'CRA Monthly Usage Minimum', id: useId() },
    'MIN - SHA': {
      name: 'Monthly Usage Minimum - Plaid Inc. & CRA',
      id: useId(),
    },
    'MIN - FLAT': { name: 'Monthly Usage Minimum', id: useId() },
    'MIN - FLATEC': { name: 'Monthly Usage Minimum (End Client)', id: useId() },
  };

  const monthlyMinimums = pricingFlow.pricingSheetData.monthlyMinTierList;

  const monthlyMinimumRampValues = getMonthlyMinimumRampValues({ pricingFlow });
  const hasMonthlyMinimumRamp = !isNil(monthlyMinimumRampValues);
  const costForEachMonth = calculateCostForEachMonth({
    pricingFlow,
  });
  const allSuggestedRampValues = isNil(monthlyMinimumRampValues)
    ? monthlyMinimumRampValues
    : makeRampIncreasing(
        monthlyMinimumRampValues.map((rampValue, monthIdx) => {
          const targetContractualSpend = {
            ...costForEachMonth[monthIdx],
            value:
              costForEachMonth[monthIdx].value *
              TARGET_MINIMUM_SPEND_PERCENTAGE,
          };
          return getMonthlyMinForTargetMonthlyContractualSpend({
            targetSpend: targetContractualSpend,
            products: pricingFlow.products,
            manualQuote: pricingFlow.manualQuote,
            productsInRecommendedQuote: pricingFlow.recommendedQuote.products,
            pricingSheetData: pricingFlow.pricingSheetData,
            additionalData: pricingFlow.additionalData,
            monthIdx,
          });
        }),
      );

  const [showRampUp, setShowRampUp] = useState(false);

  if (!monthlyMinimums) {
    return null;
  }
  function setCustomRampUpRow(idx: number, value: CurrencyValueFlat | null) {
    let newMinimumRampUp = pricingFlow.additionalData.minimumRampUp;
    if (newMinimumRampUp == null) {
      throw new Error('tried to update a minimumRampUp that does not exist');
    }
    newMinimumRampUp[idx] = value;

    updateFlow(
      {
        ...pricingFlow,
        additionalData: {
          ...pricingFlow.additionalData,
          minimumRampUp: newMinimumRampUp,
        },
      },
      false,
    );
  }
  const monthlyMinimum =
    manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum;

  const percentageOfEstMonthlyUsage = Math.round(
    ((manualQuote?.monthlyMinimum?.value ??
      recommendedQuote.monthlyMinimum.value) /
      monthlyCost) *
      100,
  );
  const checkedMonthlyMinimumProductCodes =
    pricingFlow.additionalData.additionalMonthlyMinimumProductCodes ?? [];
  return (
    <div>
      <div className="border-gray-20 mb-2 flex w-full items-center justify-between rounded-xl border bg-white px-6 py-3.5 gap-[86px]">
        Monthly minimum type
        <div>
          {/* CRA, end client, etc */}
          {monthlyMinProductCodeOptions.length > 0 && (
            <div>
              <fieldset className="flex items-center space-x-2 justify-end pt-2">
                {monthlyMinProductCodeOptions.map((productCode, index) => {
                  const { name, id } = monthlyMinProductCodeToName[productCode];
                  const isChecked =
                    checkedMonthlyMinimumProductCodes.includes(productCode);
                  return (
                    <label htmlFor={id} className="flex items-center space-x-2">
                      <input
                        id={id}
                        checked={isChecked}
                        disabled={!editMode}
                        type="checkbox"
                        className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900"
                        onChange={(e) => {
                          if (isChecked) {
                            // Remove the product code from checkedMonthlyMinimumProductCodes
                            const newAdditionalMonthlyMinimumProductCodes =
                              checkedMonthlyMinimumProductCodes.filter(
                                (pc) => pc !== productCode,
                              );

                            let newAdditionalData = {
                              ...pricingFlow.additionalData,
                              additionalMonthlyMinimumProductCodes:
                                newAdditionalMonthlyMinimumProductCodes,
                            };
                            // If there are no more monthly minimums, set the manual monthly minimum to 0 and clear the monthly minimum ramps
                            let newManualQuote = pricingFlow.manualQuote;
                            if (
                              newAdditionalMonthlyMinimumProductCodes.length ===
                              0
                            ) {
                              newManualQuote = {
                                ...manualQuote,
                                monthlyMinimum: {
                                  type: CurrencyValueType.FLAT,
                                  value: 0,
                                  currency:
                                    pricingFlow.additionalData.quoteCurrency,
                                },
                              };
                              newAdditionalData = {
                                ...newAdditionalData,
                                minimumRampUp: null,
                              };
                            }
                            updateFlow(
                              {
                                ...pricingFlow,
                                manualQuote: newManualQuote,
                                additionalData: newAdditionalData,
                              },
                              false,
                            );
                          } else {
                            // Add the product code to checkedMonthlyMinimumProductCodes
                            const newAdditionalMonthlyMinimumProductCodes = [
                              ...new Set([
                                ...checkedMonthlyMinimumProductCodes,
                                productCode,
                              ]),
                            ];
                            const newAdditionalData = {
                              ...pricingFlow.additionalData,
                              additionalMonthlyMinimumProductCodes:
                                newAdditionalMonthlyMinimumProductCodes,
                            };
                            // If this is the first monthly minimum, remove the manual monthly minimum so we can use the recommended monthly minimum
                            let newManualQuote = pricingFlow.manualQuote;
                            if (
                              newAdditionalMonthlyMinimumProductCodes.length ===
                              1
                            ) {
                              newManualQuote = {
                                ...manualQuote,
                                monthlyMinimum: undefined,
                              };
                            }

                            updateFlow(
                              {
                                ...pricingFlow,
                                manualQuote: newManualQuote,
                                additionalData: newAdditionalData,
                              },
                              false,
                            );
                          }
                        }}
                      />
                      <span
                        className={classNames(
                          'ml-3 block text-sm leading-6 text-gray-700',
                        )}
                      >
                        {name}
                      </span>
                    </label>
                  );
                })}
              </fieldset>
            </div>
          )}
        </div>
      </div>
      {checkedMonthlyMinimumProductCodes.length > 0 && (
        <div className="rounded-xl border border-gray-200 bg-white">
          <table className="h-full min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter bg-gray-50"
                >
                  {/* Fixed or ramped */}
                  <div>
                    <fieldset className="flex items-center space-x-2">
                      <label
                        htmlFor={idFixedRadio}
                        className="flex items-center space-x-2"
                      >
                        <span className="ml-3 block text-sm leading-6 text-gray-700">
                          Fixed
                        </span>
                        <input
                          id={idFixedRadio}
                          checked={!hasMonthlyMinimumRamp}
                          disabled={!editMode}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900"
                          onChange={(e) => {
                            updateFlow(
                              {
                                ...pricingFlow,
                                additionalData: {
                                  ...pricingFlow.additionalData,
                                  minimumRampUp: null,
                                },
                              },
                              false,
                            );
                          }}
                        />
                      </label>
                      <label
                        htmlFor={idRampedRadio}
                        className="flex items-center space-x-2"
                      >
                        <span className="ml-3 block text-sm leading-6 text-gray-600">
                          Ramped
                        </span>
                        <input
                          id={idRampedRadio}
                          checked={hasMonthlyMinimumRamp}
                          disabled={!editMode}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900"
                          onChange={(e) => {
                            setShowRampUp(true);
                            updateFlow(
                              {
                                ...pricingFlow,
                                additionalData: {
                                  ...pricingFlow.additionalData,
                                  minimumRampUp: Array(
                                    pricingFlow.additionalData
                                      .subscriptionTerms,
                                  ).fill(null),
                                },
                              },
                              false,
                            );
                          }}
                        />
                      </label>
                    </fieldset>
                  </div>
                </th>
                <Tooltip
                  as="th"
                  scope="col"
                  className="has-tooltip border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-filter bg-gray-50"
                  location="TOP"
                  text="We suggest ~60% of Est. monthly usage revenue"
                >
                  <>Suggested minimum</>
                </Tooltip>
                <th
                  scope="col"
                  className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter bg-gray-50"
                >
                  Quote minimum
                </th>

                <Tooltip
                  as="th"
                  scope="col"
                  className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-filter bg-gray-50"
                  text={
                    <span>
                      The sum of est. usage revenue from all products
                      <br />
                      for each month, accounting for volume ramp ups
                    </span>
                  }
                  location="TOP"
                >
                  <>Est. monthly usage revenue</>
                </Tooltip>
                <Tooltip
                  as="th"
                  scope="col"
                  className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter bg-gray-50"
                  text={
                    <span>
                      Quote minimum divided by <br />
                      Est. monthly usage revenue
                    </span>
                  }
                  location="LEFT"
                >
                  % of est monthly usage revenue
                </Tooltip>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="flex h-full flex-row items-center whitespace-nowrap px-4 py-4 pr-24">
                  {hasMonthlyMinimumRamp ? (
                    <button
                      className=" mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-950 bg-fuchsia-100"
                      onClick={() => {
                        setShowRampUp(!showRampUp);
                      }}
                    >
                      {showRampUp ? (
                        <ChevronDownIcon
                          className="h-3 w-3"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="h-3 w-3"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  ) : (
                    <div className="mr-2 h-5 w-5"></div>
                  )}

                  <span className="text-sm font-medium text-gray-900">
                    Monthly minimum
                  </span>
                </td>
                <td className="px-6 py-4 align-middle">
                  <button
                    onClick={() => {
                      const newPenguinOpportunityPricingFlow = {
                        ...pricingFlow,
                        manualQuote: {
                          ...manualQuote,
                          monthlyMinimum: recommendedQuote.monthlyMinimum,
                        },
                      };
                      updateFlow(newPenguinOpportunityPricingFlow, false);
                    }}
                    disabled={!editMode}
                  >
                    <Badge color="green">
                      {formatCurrencyValue(
                        recommendedQuote.monthlyMinimum,
                        MINIMUMS_DECIMAL_PLACES,
                      )}
                    </Badge>
                  </button>
                </td>
                <td className="overflow-show h-full w-full p-0 align-top">
                  {/* Monthly minimum */}
                  <QuotePriceEditable
                    quotePrice={monthlyMinimum}
                    updateQuotePrice={(quotePrice) => {
                      if (
                        quotePrice &&
                        quotePrice.type !== CurrencyValueType.FLAT
                      ) {
                        datadogRum.addError(
                          `Invalid quote price type: ${quotePrice.type}`,
                        );
                        return;
                      }
                      const newPenguinOpportunityPricingFlow = {
                        ...pricingFlow,
                        manualQuote: {
                          ...manualQuote,
                          monthlyMinimum: quotePrice ?? undefined,
                        },
                      };
                      updateFlow(newPenguinOpportunityPricingFlow, false);
                    }}
                    quoteCurrency={pricingFlow.additionalData.quoteCurrency}
                    showChangePriceTypeMenu={false}
                    numDecimals={MINIMUMS_DECIMAL_PLACES}
                    // Everything below is not used right now
                    validPriceTypes={[CurrencyValueType.FLAT]}
                    stickerPrice={monthlyMinimum} // dummy value
                    cost={monthlyMinimum} // dummy value
                    productName={''} // dummy value
                    validTierMinimumTypes={[]} // dummy value
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4 pr-24 align-middle">
                  <span className="text-sm text-gray-500">
                    {formatCurrencyValue(
                      {
                        type: CurrencyValueType.FLAT,
                        value: monthlyCost,
                        currency: pricingFlow.additionalData.quoteCurrency,
                      },
                      MINIMUMS_DECIMAL_PLACES,
                    )}
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 pr-24 align-top align-middle">
                  <span className="text-sm text-gray-500">
                    {_.isFinite(percentageOfEstMonthlyUsage)
                      ? `${percentageOfEstMonthlyUsage}%`
                      : 'N/A'}
                  </span>
                </td>
              </tr>

              {/* Monthly minimum ramp up rows */}
              {monthlyMinimumRampValues &&
                monthlyMinimumRampValues.map((quoteValue, idx) => {
                  const isLastRow = idx === monthlyMinimumRampValues.length - 1;
                  const percentageOfMonthlyRevenue = Math.round(
                    (quoteValue.value / costForEachMonth[idx].value) * 100,
                  );
                  const updateValue = (value: CurrencyValueFlat) => {
                    // this state occurs when a user just clicks on the value and doesn't change it
                    if (value.value === monthlyMinimum.value) {
                      setCustomRampUpRow(idx, null);
                    } else {
                      setCustomRampUpRow(idx, value);
                    }
                  };
                  const month = idx + 1;
                  const show = hasMonthlyMinimumRamp && showRampUp;

                  return (
                    <Transition
                      key={idx}
                      as="tr"
                      className="overflow-hidden"
                      show={show}
                      enter="transition-all duration-300"
                      enterFrom="max-h-0 opacity-0"
                      enterTo="max-h-[48rem] opacity-100"
                      leave="transition-all duration-300"
                      leaveFrom="max-h-[48rem] opacity-100"
                      leaveTo="max-h-0 opacity-0"
                    >
                      <MinimumRampUpRow
                        isLastRow={isLastRow}
                        quoteValue={quoteValue}
                        percentageOfMonthlyRevenue={percentageOfMonthlyRevenue}
                        costForThisMonth={costForEachMonth[idx]}
                        suggestedMinimumValue={
                          allSuggestedRampValues?.[idx] ??
                          recommendedQuote.monthlyMinimum
                        }
                        updateValue={updateValue}
                        month={month}
                      />
                    </Transition>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function fixedPrice(productQuotePrice: PenguinQuotePrice): number;
function fixedPrice(productQuotePrice: undefined): undefined;
function fixedPrice(productQuotePrice?: PenguinQuotePrice): number | undefined;
function fixedPrice(productQuotePrice?: PenguinQuotePrice): number | undefined {
  if (productQuotePrice == null) {
    return undefined;
  }
  if (
    productQuotePrice.type === 'tiered' ||
    productQuotePrice.type === 'ramped'
  ) {
    throw new Error(
      `fixedPrice fxn: only can handle fixed prices, got type=${productQuotePrice.type}`,
    );
  }

  return productQuotePrice.value;
}

export const approvalLevelsHumanReadable = {
  '-1': 'Tiered',
  0: 'No approval needed',
  1: '1. Manager',
  2: '2. Head of Function',
  3: '3. Deal Desk',
  4: '4. Finance',
} as { [key: number]: string };

export const approvalBadgeColors = {
  '-1': 'gray',
  0: 'green',
  1: 'yellow',
  2: 'orange',
  3: 'orange',
  4: 'red',
} as { [key: number]: BadgeColor };

const findClosestVolumeTier = (
  product: PenguinProductWithVolume,
  productInfo: PenguinPricingInformation,
) => {
  let closestVolumeTier = null;
  let delta = Infinity;
  const volume = product.volume;
  for (const volumeTier of productInfo.monthlyVolumeTiers ?? []) {
    if (Math.abs(volumeTier.tier.value - volume.value) < delta) {
      closestVolumeTier = volumeTier;
      delta = Math.abs(closestVolumeTier.tier.value - volume.value);
    }
  }

  return closestVolumeTier;
};

// ##PenguinBestMonthlyMinTier
// Find the largest monthly minimum tier whose value is smaller than the
// effective monthly min for approvals. For example, if a product has tiers
// [100, 200, 300], and the effective monthly min is 299, return 200. This is
// because the unit prices for a product at a lower tier are higher, which is
// good for the deal health.
// NOTE: this function expects effectiveMonthlyMin to already be preprocessed!
// See #PenguinMinContractualSpend
const findMatchingMonthlyMinimumTier = (params: {
  effectiveMonthlyMin: PenguinEffectiveContractualMin;
  pricingInfo: PenguinPricingInformation;
}) => {
  const { effectiveMonthlyMin, pricingInfo } = params;
  const matchingMonthlyMinTier = (() => {
    const productMonthlyMinTiers =
      pricingInfo.monthlyMinimumTiers.sort((a, b) => {
        // sort tiers by increasing value
        return a.tier.value - b.tier.value;
      }) ?? [];
    let prevTier = first(productMonthlyMinTiers);
    for (const minimumTier of productMonthlyMinTiers) {
      if (minimumTier.tier.value > effectiveMonthlyMin.value) {
        return prevTier;
      }
      prevTier = minimumTier;
    }
    return prevTier;
  })();
  if (isNil(matchingMonthlyMinTier)) {
    datadogRum.addError(
      `Did not find matching monthly min tier ${effectiveMonthlyMin.value} ${JSON.stringify(pricingInfo.monthlyMinimumTiers.map((t) => t.tier.value))}`,
    );
  }

  return matchingMonthlyMinTier;
};

export function getL1AndSuggestedPriceForProduct({
  product,
  pricingFlow,
}: {
  product: PenguinProductWithVolume;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}): { L1Price: PenguinCV | null; suggestedPrice: PenguinCV | null } {
  const pricingInfo =
    pricingFlow.pricingSheetData.productInfo[product.id]?.currentPricingCurve
      .pricingInformation;
  if (isNil(pricingInfo) || pricingInfo.unpriceable) {
    return { L1Price: null, suggestedPrice: null };
  }
  const suggestedPriceOverrideFromVolumePricing = (() => {
    if (pricingInfo.useVolumePricing && pricingInfo.monthlyVolumeTiers) {
      // Find closest volume tier
      const closestVolumeTier = findClosestVolumeTier(product, pricingInfo);
      if (closestVolumeTier === null) {
        datadogRum.addError(
          new Error(`no volume tier found for ${product.name}`),
        );
      }
      return closestVolumeTier?.recommendedPricing ?? null;
    }
    return null;
  })();
  if (pricingInfo.nonTieredPricing) {
    // If there is no volume pricing, use the non-tiered pricing
    // If level 1 isn't set, fall back to financeApproval
    return {
      L1Price:
        pricingInfo.nonTieredPricing.level1 ??
        pricingInfo.nonTieredPricing.level4 ??
        pricingInfo.financeApproval, // @TODO(level4) - remove this fallback
      suggestedPrice:
        suggestedPriceOverrideFromVolumePricing ??
        pricingInfo.nonTieredPricing.recommendedPricing ??
        null,
    };
  } else {
    // If there is no volume pricing or non-tiered pricing, use the monthly minimum tiers
    // If level 1 isn't set, fall back to financeApproval

    // Find the closest monthly minimum tier
    const currentMinimum = computeEffectiveMinimumContractualSpend(pricingFlow);
    const closestMonthlyMinimumTier = findMatchingMonthlyMinimumTier({
      effectiveMonthlyMin: currentMinimum,
      pricingInfo: pricingInfo,
    });
    return {
      L1Price:
        closestMonthlyMinimumTier?.level1 ??
        closestMonthlyMinimumTier?.level4 ??
        pricingInfo.financeApproval, // @TODO(level4) - remove this fallback
      suggestedPrice:
        suggestedPriceOverrideFromVolumePricing ??
        closestMonthlyMinimumTier?.recommendedPricing ??
        null,
    };
  }
}

function CurrentQuoteTableRow(props: {
  isComplexDemo?: boolean;
  product: PenguinProductWithVolume;
  productPrices: PenguinProductPrices;
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote?: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  showTierEditorForProduct: (product: string) => void;
  showRampEditorForProduct: (product: string) => void;
  showVolumeColumn: boolean;
  subscriptionTerms: number;
  onQuotePriceCtrlClick: (e: React.MouseEvent) => void;
  quotePriceClassName: string;
}) {
  const {
    product,
    productPrices,
    recommendedQuote,
    manualQuote,
    updateFlow,
    showTierEditorForProduct,
    showRampEditorForProduct,
    showVolumeColumn,
  } = props;

  const { pricingFlow, editMode } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const quotePriceRef = useRef<HTMLInputElement>(null);
  const productData = productPrices[product.id];
  const pricingInfo = productData?.currentPricingCurve.pricingInformation;
  const [showRampUp, setShowRampUp] = useState(false);

  const manualPrice = manualQuote?.products?.[product.id];
  const recommendedPrice = recommendedQuote.products[product.id];
  const stickerPrice = pricingInfo?.selfServe ?? null;
  const quotePrice = manualPrice ??
    recommendedPrice ??
    stickerPrice ?? {
      currency: pricingFlow.additionalData.quoteCurrency,
      type: CurrencyValueType.FLAT,
      value: 0,
    };
  const [monthlyCost, setMonthlyCost] = useState<number | null>(() =>
    monthlyRevenueForQuotePrice(
      quotePrice,
      product.volume.value,
      product.transactionSize?.value,
      productPrices[product.id],
      null,
    ),
  );

  const [selectedCells, setSelectedCells] = useState<{
    start: number;
    end: number;
  }>({ start: -1, end: -1 });
  const { showToast } = useToast();

  useEffect(() => {
    setMonthlyCost(
      monthlyRevenueForQuotePrice(
        quotePrice,
        product.volume.value,
        product.transactionSize?.value,
        productData,
        null,
      ),
    );
  }, [quotePrice, product.volume]);

  const pricingLogs: string[] = recommendedQuote.productPricingLogs
    ? recommendedQuote.productPricingLogs[product.id]
    : [''];

  const approvalLevel = pricingFlow.approvalLevels?.[product.id];

  const { L1Price } = getL1AndSuggestedPriceForProduct({
    product: product,
    pricingFlow: pricingFlow,
  });

  const {
    approvalBadgeColor,
    approvalHumanReadable,
  }: { approvalBadgeColor: BadgeColor; approvalHumanReadable: string } =
    (() => {
      if (approvalLevel?.type === 'tiered') {
        // get max of all levels
        const level = approvalLevel.levels.reduce((acc, level) => {
          return Math.max(acc, level);
        }, 0);
        return {
          approvalBadgeColor: approvalBadgeColors[level],
          approvalHumanReadable: approvalLevelsHumanReadable[level],
        };
      } else if (approvalLevel?.type === 'fixed') {
        return {
          approvalBadgeColor: approvalBadgeColors[approvalLevel.level],
          approvalHumanReadable:
            approvalLevelsHumanReadable[approvalLevel.level] ?? '',
        };
      } else {
        return {
          approvalBadgeColor: approvalBadgeColors[-1],
          approvalHumanReadable: 'N/A',
        };
      }
    })();

  if (isNil(productData) || isNil(pricingInfo)) {
    return null;
  }

  const isPercentPricing = productData.priceType === PriceType.PERCENT;
  const numDecimals = isPercentPricing ? 4 : 3;

  function updateManualPrice(manualPrice: PenguinCV | null) {
    console.log('CALL updateManualPrice with price: ', manualPrice);
    let newManualQuote = manualQuote;

    const existingQuoteProductPrice = manualQuote?.products?.[product.id];
    if (
      (existingQuoteProductPrice?.type === CurrencyValueType.FLAT ||
        existingQuoteProductPrice?.type === CurrencyValueType.PERCENT) &&
      existingQuoteProductPrice.value === manualPrice?.value
    ) {
      // No change
      return;
    }

    if (manualPrice === null) {
      //Remove the product from the manual quote
      const { [product.id]: _, ...rest } = manualQuote?.products ?? {};
      newManualQuote = {
        ...manualQuote,
        products: rest,
      };
    } else {
      newManualQuote = {
        ...manualQuote,
        products: {
          ...(manualQuote?.products ?? {}),
          [product.id]: manualPrice,
        },
      };
    }

    updateFlow(
      {
        ...pricingFlow,
        manualQuote: newManualQuote,
      },
      false,
    );

    // Update all dependent numbers
    setMonthlyCost(
      monthlyRevenueForQuotePrice(
        quotePrice,
        product.volume.value,
        product.transactionSize?.value,
        productPrices[product.id],
        null,
      ),
    );
  }

  /***
   * Ramp up toggle
   */

  const volumeSubtext = (() => {
    if (product.rampType === 'custom' && product.customRampUp) {
      return `${product.customRampUp.length} Mo ramp`;
    } else if (product.rampType === 'linear' && product.linearRampUpConfig) {
      return `${product.linearRampUpConfig.months} Mo ramp`;
    } else if (
      product.rampType === 'linear_quarterly' &&
      product.linearRampUpConfig
    ) {
      return `${product.linearRampUpConfig.months} Mo ramp`;
    }
  })();

  const rampUpRows = getVolumeRampUpRows(product);

  const hasRampUp = rampUpRows.length > 0;
  const lastRowIdx = rampUpRows.length - 1;

  const isTiered = quotePrice.type === 'tiered';
  const isRamped = quotePrice.type === 'ramped';

  const getMonthlyRevenueTooltip = ({
    volume,
  }: {
    volume: number;
  }): ReactNode | null => {
    const formatNum = (n: number): string => {
      return n.toLocaleString('en-US', { maximumFractionDigits: 3 });
    };
    if (quotePrice.type === 'tiered') {
      const tiers = quotePrice.tiers;
      const tiersAndVolumes = _.zip(
        tiers,
        getVolumeForEachTier({
          tiers: tiers,
          volume: volume,
        }),
      ) as [PenguinTier, number][];
      const nonZeroTiersAndVolume = tiersAndVolumes.filter(
        ([tier, volume]) => volume !== 0,
      );
      if (nonZeroTiersAndVolume.length === 0) {
        return null;
      }

      return (
        <div>
          {nonZeroTiersAndVolume.map(([tier, volume], i) => {
            const isLast = i + 1 === nonZeroTiersAndVolume.length;
            const priceString = tier.currencyValue.value.toLocaleString(
              'en-US',
              {
                minimumFractionDigits: numDecimals,
              },
            );
            return (
              <div>
                <span className="font-extrabold">Tier {i + 1}</span>{' '}
                <span className="font-normal">
                  ({tier.minimum.value}-{tier.minimum.value + volume - 1})
                </span>
                <span className="font-semibold">
                  : {priceString} × {formatNum(volume)} {!isLast && '+'}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const tieredProductMonthlyRevenueTooltip = isTiered
    ? getMonthlyRevenueTooltip({
        volume: product.volume.value,
      })
    : null;

  const roundedQuotePrice =
    quotePrice.type !== 'ramped' && quotePrice.type !== 'tiered'
      ? { ...quotePrice, value: round(quotePrice.value, numDecimals) }
      : quotePrice;

  return (
    <>
      <tr>
        {/* Product */}
        <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 px-6 py-4">
          <div className="h-5 w-5 flex-shrink-0">
            {hasRampUp && (
              <button
                className="absolute flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-900 bg-fuchsia-50"
                onClick={() => {
                  setShowRampUp(!showRampUp);
                }}
              >
                {showRampUp ? (
                  <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
                )}
              </button>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-900">
              {productData.name}
            </span>
            <span className="text-xs text-gray-600">
              {productData.unitDefinition ?? 'N/A'}
            </span>
            {product.transactionSize !== undefined && (
              <span className="text-xs text-gray-600">
                Avg transaction size:{' '}
                {formatCurrencyValue(product.transactionSize)}
              </span>
            )}
            {(!isNil(productData.priceFloor) ||
              !isNil(productData.priceCap)) && (
              <div className="text-xs text-gray-600 flex flex-row gap-2">
                <span>
                  {!isNil(productData.priceFloor)
                    ? `Price floor: ${formatCurrencyValue(productData.priceFloor)}`
                    : ''}
                </span>
                <span>
                  {!isNil(productData.priceCap)
                    ? ` Price cap: ${formatCurrencyValue(productData.priceCap)}`
                    : ''}
                </span>
              </div>
            )}
          </div>
        </td>
        {/* Monthly est. volume */}
        {showVolumeColumn && (
          <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
            <div className="flex flex-col">
              {pricingInfo.unpriceable ? (
                <span>N/A</span>
              ) : (
                <>
                  <span className="">
                    {product.volume.value.toLocaleString()}
                  </span>
                  <span className="">{volumeSubtext}</span>
                </>
              )}
            </div>
          </td>
        )}
        {/* Rack Rate */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-purple-700">
          <button
            className=""
            disabled={isNil(stickerPrice) || !editMode}
            title="Set quote price to rack rate"
            onClick={() => {
              if (isNil(stickerPrice)) return;
              const roundedStickerPrice = {
                ...stickerPrice,
                value: round(stickerPrice.value, numDecimals),
              };
              updateManualPrice(roundedStickerPrice);
            }}
          >
            <Badge color="purple">
              {formatCurrencyValue(stickerPrice, numDecimals)}
            </Badge>
          </button>
        </td>
        {/* L1 Price */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-orange-700">
          <button
            className=""
            disabled={isNil(L1Price) || !editMode}
            title="Set quote price to L1 price"
            onClick={() => {
              if (isNil(L1Price)) return;
              const roundedL1Price = {
                ...L1Price,
                value: round(L1Price.value, numDecimals),
              };
              updateManualPrice(roundedL1Price);
            }}
          >
            <Badge color="orange">
              {formatCurrencyValue(L1Price, numDecimals)}
            </Badge>
          </button>
        </td>
        {/* Suggested price */}
        <td className="has-tooltip whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium">
          <button
            className=""
            disabled={isNil(recommendedPrice) || !editMode}
            title="Set quote price to suggested price"
            onClick={() => {
              if (isNil(recommendedPrice)) return;
              updateManualPrice(recommendedPrice);
            }}
          >
            <Badge color="green">
              {formatCurrencyValue(
                !isNil(recommendedPrice) ? recommendedPrice : null,
                numDecimals,
              )}
            </Badge>
            {window.isDeveloperModeEnabled && (
              // Only show this tooltip in developer mode
              <span className="tooltip z-20 -ml-60 mt-10 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
                {pricingLogs.map((pricingLog) => {
                  return (
                    <div key={pricingLog} className="text-xs text-gray-100">
                      {pricingLog}
                    </div>
                  );
                })}
              </span>
            )}
          </button>
        </td>
        {/* Quote price */}
        <td
          className="overflow-show h-full w-full p-0 align-top border-b border-gray-200 min-w-[120px]"
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {
            if (e.metaKey) {
              if (!isTiered && !isPercentPricing) {
                props.onQuotePriceCtrlClick(e);
              }
            } else {
              if (isTiered) {
                showTierEditorForProduct(product.id);
              } else if (isRamped) {
                showRampEditorForProduct(product.id);
              } else {
                if (quotePriceRef.current !== null) {
                  quotePriceRef.current.focus();
                }
              }
            }
          }}
        >
          {/* quote price for product */}
          {pricingInfo.unpriceable ? (
            <div className="h-full flex items-center pl-4 text-sm font-medium text-gray-500">
              N/A
            </div>
          ) : isTiered ? (
            <EditableIndicator
              className="py-4 pl-4 text-sm"
              readonly={!editMode}
            >
              Tiered
            </EditableIndicator>
          ) : isRamped ? (
            <EditableIndicator
              className="py-4 pl-4 text-sm"
              readonly={!editMode}
            >
              Ramped
            </EditableIndicator>
          ) : (
            <QuotePriceEditable
              quotePrice={roundedQuotePrice}
              updateQuotePrice={(quotePrice) => {
                if (
                  quotePrice &&
                  quotePrice.type !== CurrencyValueType.FLAT && // I'm not sure why we even need to check this, but I'll leave it for now
                  quotePrice.type !== CurrencyValueType.PERCENT
                ) {
                  datadogRum.addError(
                    `Invalid quote price type: ${quotePrice.type}`,
                  );
                  return;
                }
                updateManualPrice(quotePrice);
              }}
              showChangePriceTypeMenu={false}
              numDecimals={numDecimals}
              quoteCurrency={pricingFlow.additionalData.quoteCurrency}
              // Everything below here is not actually used right now
              validPriceTypes={[
                isPercentPricing
                  ? CurrencyValueType.PERCENT
                  : CurrencyValueType.FLAT,
              ]}
              stickerPrice={stickerPrice}
              cost={L1Price}
              productName={productData.name}
              validTierMinimumTypes={[CurrencyValueType.FLAT]}
            />
          )}
        </td>
        {/* Approval level */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-green-700">
          <Badge color={approvalBadgeColor}>{approvalHumanReadable}</Badge>
        </td>
        {/* Monthly revenue */}
        <Tooltip
          as="td"
          className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500"
          location="LEFT"
          disableTooltip={
            !isTiered || isNil(tieredProductMonthlyRevenueTooltip)
          }
          text={isTiered && tieredProductMonthlyRevenueTooltip}
        >
          {props.isComplexDemo
            ? 'See below'
            : isNil(monthlyCost)
              ? 'N/A'
              : formatCurrency({
                  amount: monthlyCost,
                  currency: pricingFlow.additionalData.quoteCurrency,
                })}
        </Tooltip>
      </tr>

      {/* Volume ramp up rows */}
      <ClickAndDragProvider
        onMouseUp={({ start, end }) => {
          // COPY
          navigator.clipboard.writeText(
            rampUpRows.slice(start, end + 1).join('\n'),
          );
          console.log('copied: ', rampUpRows.slice(start, end + 1).join('\n'));
          // Show toast
          showToast({
            title: 'Copied volume ramp up rows',
            subtitle: undefined,
            type: 'info',
          });
        }}
        unidirectional={false}
      >
        {rampUpRows.map((volume, idx) => {
          return (
            <MonthlyVolumeRampRow
              volume={volume}
              idx={idx}
              lastRowIdx={lastRowIdx}
              hasRampUp={hasRampUp}
              showRampUp={showRampUp}
              product={product}
              quotePrice={quotePrice}
              numDecimals={numDecimals}
              productPrices={productPrices}
            />
          );
        })}
      </ClickAndDragProvider>
      {/* Volume ramp-up sum */}
      <Transition
        as="tr"
        className="overflow-hidden"
        show={hasRampUp && showRampUp}
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-[48rem] opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-[48rem] opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        <td className="flex h-full items-center gap-2 bg-gray-100 px-6 py-4 text-sm text-gray-500">
          {`Total volume (${rampUpRows.length} months)`}
        </td>
        <td className="bg-gray-100 text-sm text-gray-500">
          <span className="pl-4 font-semibold">
            {sum(rampUpRows).toLocaleString()}
          </span>
        </td>
        <td colSpan={6} className="bg-gray-100"></td>
      </Transition>
    </>
  );
}

function MonthlyVolumeRampRow(props: {
  volume: number;
  idx: number;
  lastRowIdx: number;
  hasRampUp: boolean;
  showRampUp: boolean;
  product: PenguinProductWithVolume;
  quotePrice: PenguinQuotePrice;
  numDecimals: number;
  productPrices: PenguinProductPrices;
}) {
  const {
    volume,
    idx,
    lastRowIdx,
    hasRampUp,
    showRampUp,
    product,
    quotePrice,
    numDecimals,
    productPrices,
  } = props;
  const { handleMouseDown, handleMouseOver, handleMouseUp, isCellSelected } =
    useClickAndDrag();
  const { editMode, pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();
  const subscriptionTerms = pricingFlow.additionalData.subscriptionTerms;
  const rampVolumeInputRef = useRef<HTMLInputElement>(null);
  const getMonthlyRevenueTooltip = ({
    volume,
  }: {
    volume: number;
  }): ReactNode | null => {
    const formatNum = (n: number): string => {
      return n.toLocaleString('en-US', { maximumFractionDigits: 3 });
    };
    if (quotePrice.type === 'tiered') {
      const tiers = quotePrice.tiers;
      const tiersAndVolumes = _.zip(
        tiers,
        getVolumeForEachTier({
          tiers: tiers,
          volume: volume,
        }),
      ) as [PenguinTier, number][];
      const nonZeroTiersAndVolume = tiersAndVolumes.filter(
        ([tier, volume]) => volume !== 0,
      );
      if (nonZeroTiersAndVolume.length === 0) {
        return null;
      }

      return (
        <div>
          {nonZeroTiersAndVolume.map(([tier, volume], i) => {
            const isLast = i + 1 === nonZeroTiersAndVolume.length;
            const priceString = tier.currencyValue.value.toLocaleString(
              'en-US',
              {
                minimumFractionDigits: numDecimals,
              },
            );
            return (
              <div>
                <span className="font-extrabold">Tier {i + 1}</span>{' '}
                <span className="font-normal">
                  ({tier.minimum.value}-{tier.minimum.value + volume - 1})
                </span>
                <span className="font-semibold">
                  : {priceString} × {formatNum(volume)} {!isLast && '+'}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const createAnalyticsEvent = useAnalyticsContext();

  const rampUpMonthlyRevenueTooltip = getMonthlyRevenueTooltip({
    volume,
  });
  function setCustomRampUpRow(idx: number, value: number) {
    let newCustomRampProduct: PenguinProductWithVolume;
    if (product.rampType !== 'custom') {
      newCustomRampProduct = {
        ...product,
        rampType: 'custom',
        customRampUp: getVolumeRampUpRows(product),
      };
    } else {
      newCustomRampProduct = product;
    }

    // Modify new value at the idx
    newCustomRampProduct.customRampUp[idx] = value;
    if (idx === newCustomRampProduct.customRampUp.length - 1) {
      // If it's the final month we also need to update the volume
      newCustomRampProduct.volume.value = value;
    }

    updateFlow(
      {
        ...pricingFlow,
        products: pricingFlow.products.map((p) => {
          if (p.id === product.id) {
            return newCustomRampProduct;
          }
          return p;
        }) satisfies PenguinProductWithVolume[],
      },
      false,
    );
  }

  return (
    <Transition
      as="tr"
      className="overflow-hidden"
      show={hasRampUp && showRampUp}
      enter="transition-all duration-300"
      enterFrom="max-h-0 opacity-0"
      enterTo="max-h-[48rem] opacity-100"
      leave="transition-all duration-300"
      leaveFrom="max-h-[48rem] opacity-100"
      leaveTo="max-h-0 opacity-0"
    >
      <td className="flex h-full items-center gap-2 bg-gray-100 px-6 py-4 text-sm text-gray-500">
        <ArrowDownRightIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
        Month {idx + 1}
      </td>
      <td
        className={classNames(
          'text-sm text-gray-500',
          isCellSelected(idx) ? 'bg-blue-200' : 'bg-gray-100',
        )}
        onMouseDown={() => handleMouseDown(idx)}
        onMouseOver={() => handleMouseOver(idx)}
        onMouseUp={() => handleMouseUp(idx)}
        onPaste={(e) => {
          e.preventDefault();
          const text = e.clipboardData.getData('text');
          const rows = text.split('\n');
          const newRampUp = getVolumeRampUpRows(product);
          let i = idx;
          for (const row of rows) {
            if (i >= newRampUp.length - 1) {
              // you can't edit the last row because that's the volume at scale
              break;
            }
            newRampUp[i] = parseFloat(row);
            i++;
          }
          const newCustomRampProduct: PenguinProductWithVolume = {
            ...product,
            rampType: 'custom',
            customRampUp: newRampUp,
          };
          updateFlow(
            {
              ...pricingFlow,
              products: pricingFlow.products.map((p) => {
                if (p.id === product.id) {
                  return newCustomRampProduct;
                }
                return p;
              }),
            },
            false,
          );
          createAnalyticsEvent({
            name: 'pricing_flow__copy_pasted_volume_ramp_up_rows',
            eventData: {
              pricing_flow_id: pricingFlow.id,
              product_id: product.id,
            },
          });
        }}
      >
        {idx !== lastRowIdx ? (
          <EditableIndicator
            className="pl-4"
            backgroundColor="gray-100"
            readonly={!editMode}
          >
            <FormattedNumberField
              type="text"
              ref={rampVolumeInputRef}
              data-ramp-volume-editable
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                rampVolumeInputRef,
                'data-ramp-volume-editable',
              )}
              value={volume}
              required={true}
              className="h-full w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
              updateValue={(value: number) => {
                setCustomRampUpRow(idx, value);
              }}
              numberDecimals={0}
              disabled={!editMode}
            />
          </EditableIndicator>
        ) : (
          <span className="pl-4 font-semibold">{volume.toLocaleString()}</span>
        )}
      </td>
      <td colSpan={5} className="bg-gray-100">
        {idx === 0 && product.rampType === 'custom' && (
          <button
            className="pl-8 text-sm text-blue-500 hover:text-blue-700"
            disabled={!editMode}
            onClick={() => {
              const newLinearProduct: PenguinProductWithVolume = {
                ...product,
                rampType: 'linear',
                linearRampUpConfig: {
                  start: 0,
                  months: subscriptionTerms,
                },
              };

              updateFlow(
                {
                  ...pricingFlow,
                  products: pricingFlow.products.map((p) => {
                    if (p.id === product.id) {
                      return newLinearProduct;
                    }
                    return p;
                  }),
                },
                false,
              );
            }}
          >
            Reset to linear ramp up between first and last month
          </button>
        )}
      </td>
      <Tooltip
        as="td"
        className="bg-gray-100 px-6 py-4 text-sm text-gray-500"
        text={rampUpMonthlyRevenueTooltip}
        location={'LEFT'}
        disableTooltip={rampUpMonthlyRevenueTooltip == null}
      >
        {formatCurrency({
          amount: monthlyRevenueForQuotePrice(
            quotePrice,
            volume,
            product.transactionSize?.value,
            productPrices[product.id],
            idx,
          ),
          currency: pricingFlow.additionalData.quoteCurrency,
        })}
      </Tooltip>
    </Transition>
  );
}
